// @flow
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../components/auth/auth.service';
import { ImpersonationService } from '../../components/auth/impersonation.service';

import { DarkPoolService } from './shared/dark-pool.service';
import { DarkPoolCheckContactComponent } from './shared/dark-pool-check-contact/dark-pool-check-contact.component';

import { FileUploader } from 'ng2-file-upload';
import { FileService } from '../../components/files/file.service';
import { s3Bucket } from '../app.constants';

import * as XLSX from 'xlsx';
import moment from 'moment-timezone';

@Component({
  selector: 'darkpool',
  template: require('./dark-pool.html')
})
export class DarkPoolComponent implements OnInit {
  @ViewChild('timeMachineTab') timeMachineTab;
  @ViewChild('domainAnalysisTab') domainAnalysisTab;
  @ViewChild('bouncebacksTab') bouncebacksTab;
  @ViewChild('botDetectionTab') botDetectionTab;
  @ViewChild('emailScannersTab') emailScannersTab;
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  currentOrg;

  static parameters = [Router, HttpClient, BsModalService, AuthService, ImpersonationService, DarkPoolService, FileService];
  constructor(router: Router, http: HttpClient, modalService: BsModalService, authService: AuthService, impersonation: ImpersonationService, darkPoolService: DarkPoolService, fileService: FileService) {
    this.router = router;
    this.http = http;
    this.modalService = modalService;
    this.authService = authService;
    this.impersonation = impersonation;
    this.darkPoolService = darkPoolService;
    this.fileService = fileService;
    this.selectedProperty = 'unresponsiveRate';

    this.BOUNCEBACK_REPORT_COLUMNS = [
      'Bounceback Date/Time',
      'SMTP Error Code',
      'SMTP Reply Code',
      'Bounceback Type',
      'Bounceback from Address',
      'Bounceback Message',
      'Email Name',
      'Email Subject Line',
      'Email Group',
      'Eloqua Contact ID'
    ];

    this.loading = false;
    this.loadingAvgSends = false;
    this.firstTime = true;
    this.currentOrg = { darkPoolSetting: 'off' };
    this.data = {};
    this.bouncebacks = {};
    this.bouncebackCountsByDay = [];
    this.settings = {};

    //Track which component tab is active, if any.
    this.activeTab = {
      timeMachine: false,
      domainAnalysis: false,
      bouncebacks: false,
      botDetection: false,
      emailScanners: false
    };

    this.loadTimeMachine = false;
    this.loadDomainAnalysis = false;
    this.loadBouncebacks = false;
    this.loadBotDetection = false;
    this.loadEmailScanners = false;

    this.showUnresponsive = false;

    this.numberOfTreesValue = 'numberOfTreesWasteful';

    this.uploadedFiles = []; //<<< TODO: Do we need this?
    this.uploadingFiles = [];
    this.uploadingFilesProblem = false;
    this.uploadSub = this.fileService.complete.subscribe(() => {
      //Hide progress bars after a few seconds.
      /*
      setTimeout(() => {
        this.uploadingFiles = [];
      }, 5000);
      */
      this.checkingFiles = false;
    });

    this.uploader = new FileUploader({
      url: `http://${s3Bucket}.s3.amazonaws.com`,
      disableMultipart: true
    });

    this.contactBreakdown = [];
    this.isBreakdownCollapsed = true;
    this.contactBreakdownLabels = this.darkPoolService.getContactBreakdownLabels();
    this.contactBreakdownDescriptions = this.darkPoolService.getContactBreakdownDescriptions();
  } //end: ctor

  ngOnInit() {
    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.isDemo = this.impersonation.isDemoMode();

    //For Impersonation
    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      this.currentUser = user;
    });

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.init();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      //Make sure we have an org and it acutally changed.
      if(typeof org !== 'undefined' && org._id !== this.currentOrg._id) {
        this.currentOrg = org;
        this.init();
      }
    });

    //For uploading files
    this.uploader.onAfterAddingAll = files => {
      this.promises = [];
      this.uploadingFiles = files;
      this.checkingFiles = true;

      //Check each file's structure to make sure they are correct.
      this.uploadingFiles.forEach(file => {
        if(typeof file.file !== 'undefined' && typeof file.file.rawFile !== 'undefined') {
          this.promises.push(this.checkFile(file));
        }
      });

      //Wait for all file checks to complete before uploading.
      Promise.all(this.promises).then(() => {
        //console.log('-----> ALL FILES HAVE BEEN CHECKED...'); //debug
        this.checkingFiles = false;
        var folder = `${this.currentOrg.name}_${this.currentOrg._id}`;
        this.fileService.uploadFilesFromComputer(files, this.uploadedFiles, folder, this.currentOrg._id);
      });
    };
  }

  checkFile(file) {
    var promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      var currFile = file.file.rawFile;

      if(currFile.type === 'text/csv') {
        //console.log('----> LOADING A CSV FILE.'); //debug
        reader.readAsText(currFile);
        reader.onload = () => {
          var workbook = XLSX.read(reader.result, { type: 'string' });
          var headers = this.getHeaderRow(workbook.Sheets[workbook.SheetNames[0]]);
          if(!this.checkHeaders(headers)) {
            //console.log('----> CSV FILE HAS A PROBLEM.'); //debug
            file.isProblem = true;
            this.uploadingFilesProblem = true;
          }
          resolve();
        };
      }
      else if(currFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //console.log('----> LOADING AN EXCEL FILE.'); //debug
        reader.readAsBinaryString(currFile);
        reader.onload = () => {
          var workbook = XLSX.read(reader.result, { type: 'binary' });
          var headers = this.getHeaderRow(workbook.Sheets[workbook.SheetNames[0]]);
          if(!this.checkHeaders(headers)) {
            //console.log('----> EXCEL FILE HAS A PROBLEM.'); //debug
            file.isProblem = true;
            this.uploadingFilesProblem = true;
          }
          resolve();
        };
      }

      reader.onerror = function() {
        console.log(reader.error);
        reject();
      };
    });
    return promise;
  }

  getHeaderRow(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var col;
    var row = range.s.r; //start in the first row

    //walk every column in the range
    for(col = range.s.c; col <= range.e.c; ++col) {
      var cell = sheet[XLSX.utils.encode_cell({c: col, r: row})]; //find the cell in the header row
      var hdr = `UNKNOWN ${col}`;
      if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      //Look at row 3 instead.
      if(hdr.includes('Email Bounceback History with Messages')) {
        row++;
        row++;
        col--;
      }
      else {
        headers.push(hdr);
      }
    }
    return headers;
  }

  checkHeaders(headers) {
    var headersOk = true;
    this.BOUNCEBACK_REPORT_COLUMNS.forEach(neededHeader => {
      if(!headers.find(el => el === neededHeader)) headersOk = false;
    });
    return headersOk;
  }

  ngOnDestroy() {
    if(this.uploadSub) this.uploadSub.unsubscribe();
    if(this.userSub) this.userSub.unsubscribe();
    if(this.orgSub) this.orgSub.unsubscribe();
  }

  analyzeUnresponsive() {
    this.showUnresponsive = true;
    this.staticTabs.tabs[1].active = true; //2nd tab indexed at 0.
    setTimeout(() => {
      this.showUnresponsive = false;
    }, 10);
  }

  analyzeBouncebacks() {
    this.staticTabs.tabs[2].active = true; //3rd tab indexed at 0.
  }

  init() {
    //get the most recent dark pool settings from the current Org.
    this.settings = this.darkPoolService.getLatestDarkPoolSettings(this.currentOrg);
    this.displayThreshold = this.settings.threshold;

    //build link to Eloqua Insight
    if(typeof this.currentUser.eloqua !== 'undefined') {
      this.insightLink = `https://reporting.${this.currentUser.eloqua._json.urls.base.split('.')[1]}.eloqua.com/analytics/saw.dll?bieehome`;
    }

    //Set threshold for display.
    if(this.darkPoolService.tempSettings) {
      this.displayThreshold = this.darkPoolService.tempSettings.threshold;
    }

    if(this.isDemo) {
      this.loadDemoData();
      return;
    }

    this.loadSummaryData();
    this.loadAverageSends();
    this.checkBouncebacks();
  }

  //Callback used by dark-pool-time-period to handle changes in time-period.
  refresh = () => {
    this.data = {};
    this.unresponsiveChart = null;
    this.bouncebackChart = null;
    this.wastefulChart = null;
    this.misclassifiedChart = null;

    //Set threshold for display.
    if(this.darkPoolService.tempSettings) {
      this.displayThreshold = this.darkPoolService.tempSettings.threshold;
    }

    this.loadSummaryData();
    this.refreshTabs();
  }

  loadSummaryData() {
    //TODO: Where to get the periodIndex?
    this.loading = true;
    this.darkPoolService.getSummaryData(0).then(data => {
      this.data = data;

      //Calc CO2 and numberOfTrees
      this.co2EmittedTotal = Math.round(this.data.totalSends * 10 / 1000);
      this.numberOfTreesTotal = Math.round(this.co2EmittedTotal / 25);

      this.co2EmittedWasteful = Math.round(this.data.wastefulSends * 10 / 1000);
      this.numberOfTreesWasteful = Math.round(this.co2EmittedWasteful / 25);

      this.numberOfTrees = this.numberOfTreesWasteful;

      this.contactBreakdown = [];
      if(this.data.contactBreakdownCounts.length > 0) {
        var countObj = this.data.contactBreakdownCounts[0];
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.scoreUndetermined, value: countObj.scoreUndetermined, percent: countObj.scoreUndetermined / this.data.totalContacts, description: this.contactBreakdownDescriptions.scoreUndetermined });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score0, value: countObj.score0, percent: countObj.score0 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score0 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score1, value: countObj.score1, percent: countObj.score1 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score1 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score2, value: countObj.score2, percent: countObj.score2 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score2 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score3, value: countObj.score3, percent: countObj.score3 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score3 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score4, value: countObj.score4, percent: countObj.score4 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score4 });
      }

      this.unresponsiveChart = {
        name: 'Unresponsive',
        value: this.data.unresponsiveContacts,
        total: this.data.totalContactsAnalyzed
      };

      this.wastefulChart = {
        name: 'Wasteful',
        value: this.data.wastefulSends,
        total: this.data.totalSends
      };

      //If the bounceback report is loaded, show real data -- otherwise, show sample.
      if(this.settings.isBouncebackLoaded) {
        this.darkPoolService.getBouncebackSummary(0).then(bouncebacks => {
          this.bouncebacks = bouncebacks;

          this.bouncebackChart = {
            name: 'Bouncebacks',
            value: this.bouncebacks.totalBounces,
            total: this.data.totalSends
          };

          this.misclassifiedChart = {
            name: 'Misclassified',
            value: this.bouncebacks.hardBouncebacksValid,
            total: this.bouncebacks.allBouncebacksValid
          };
        });
      }
      else {
        //Load sample data
        this.bouncebacks = {};
        this.bouncebacks.totalBounces = this.data.totalBounces;
        this.bouncebacks.allBouncebacksValid = parseInt(this.data.totalBounces * 0.129);
        this.bouncebacks.hardBouncebacksValid = parseInt(this.bouncebacks.allBouncebacksValid * 0.427);

        this.bouncebackChart = {
          name: 'Bouncebacks',
          value: this.data.totalBounces,
          total: this.data.totalSends
        };

        this.misclassifiedChart = {
          name: 'Misclassified',
          value: this.bouncebacks.hardBouncebacksValid,
          total: this.bouncebacks.allBouncebacksValid
        };

        //Store a couple values globally for DP Sample data.
        this.darkPoolService.sampleStats = {
          bounceLimit: this.data.totalBounces
        };
      }
      this.loading = false;
    })
      .catch(e => {
        console.log('Exception retrieving DP summary.', e);
      });
  }

  checkBouncebacks() {
    this.bouncebackNudge = null;
    this.darkPoolService.getLatestBouncebacksDay(this.currentOrg._id).then(date => {
      if(date) {
        const today = moment();
        const latestDate = moment(date);
        const diff = today.diff(latestDate, 'days');
        this.latestBounceback = latestDate.format('MMMM D, YYYY');

        if(diff >= 90) {
          this.bouncebackNudge = 'danger';
        }
        else if(diff >= 45) {
          this.bouncebackNudge = 'warning';
        }
        else if(diff >= 30) {
          this.bouncebackNudge = 'success';
        }
      }
    });
  }

  numberOfTreesToggleChanged() {
    if(this.numberOfTreesValue === 'numberOfTreesTotal') {
      this.numberOfTrees = this.numberOfTreesTotal;
    }
    else {
      this.numberOfTrees = this.numberOfTreesWasteful;
    }
  }

  toggleDetails(event) {
    event.stopPropagation();
    this.isBreakdownCollapsed = !this.isBreakdownCollapsed;
  }

  //Check which tab is active and manually refresh that one.
  //Set all other tabs visibility to false, so they will lazy (re)load if activated.
  refreshTabs() {
    if(this.activeTab.timeMachine) {
      this.timeMachineTab.loadData();
    }
    else {
      this.loadDomainAnalysis = false;
      this.loadBouncebacks = false;
      this.loadBotDetection = false;
      this.loadEmailScanners = false;
    }

    if(this.activeTab.domainAnalysis) {
      this.domainAnalysisTab.loadData();
    }
    else {
      this.loadTimeMachine = false;
      this.loadBouncebacks = false;
      this.loadBotDetection = false;
      this.loadEmailScanners = false;
    }

    if(this.activeTab.bouncebacks) {
      this.bouncebacksTab.loadData();
    }
    else {
      this.loadTimeMachine = false;
      this.loadDomainAnalysis = false;
      this.loadBotDetection = false;
      this.loadEmailScanners = false;
    }

    if(this.activeTab.botDetection) {
      this.botDetectionTab.loadData();
    }
    else {
      this.loadTimeMachine = false;
      this.loadDomainAnalysis = false;
      this.loadBouncebacks = false;
      this.loadEmailScanners = false;
    }

    if(this.activeTab.emailScanners) {
      this.emailScannersTab.loadData();
    }
    else {
      this.loadTimeMachine = false;
      this.loadDomainAnalysis = false;
      this.loadBouncebacks = false;
      this.loadBotDetection = false;
    }
  }

  cancelUpload(index) {
    this.fileService.cancelUpload(index);
  }

  clearUploadList() {
    this.uploadingFiles = [];
    this.uploadingFilesProblem = false;
  }

  checkContact() {
    const initialState = {};
    this.modalService.show(DarkPoolCheckContactComponent, { initialState, class: 'modal-lg' });
  }

  exportAllContacts() {
    this.exporting = true;
    this.darkPoolService.exportAllContactIDs(0).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All contacts';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      this.darkPoolService.updateScores(contactIDs);
      new ngxCsv(contactIDs, filename, options);
      this.exporting = false;
    });
  }

  exportContacts(label) {
    this.exportingByScore = true;
    var score = this.darkPoolService.encodeScore(label);
    this.darkPoolService.exportContactIDsByScore(0, score).then(contactIDs => {
      var headers = ['Eloqua Contact ID', 'Domain', 'Catch All', 'Other Activity', 'Score'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Contacts ${label}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      this.darkPoolService.updateScores(contactIDs);
      new ngxCsv(contactIDs, filename, options);
      this.exportingByScore = false;
    });
  }

  loadAverageSends() {
    this.loadingAvgSends = true;
    this.averageSends = [];
    this.darkPoolService.getAverageSends(this.currentOrg._id, 0).then(data => {
      this.averageSends = data;
      this.loadingAvgSends = false;
    });
  }

  exportAverageSendsDomains() {
    this.exporting = true;
    this.darkPoolService.exportAverageSendsDomains(0).then(domains => {
      var headers = ['Average Sends', 'Domain'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Average sends domains';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(domains, filename, options);
      this.exporting = false;
    });
  }

  loadBouncebackCountsByDay() {
    this.loadingBouncebackCountsByDay = true;
    this.BouncebacksByMonth = [];
    this.darkPoolService.getBouncebackCountsByDay(this.currentOrg._id).then(data => {
      this.bouncebackCountsByDay = data;
      this.loadingBouncebackCountsByDay = false;
    });
  }

  loadDemoData() {
    this.darkPoolService.getDemoData('summary').then(data => {
      this.data = data;

      this.contactBreakdown = [];
      if(this.data.contactBreakdownCounts.length > 0) {
        var countObj = this.data.contactBreakdownCounts[0];
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.scoreUndetermined, value: countObj.scoreUndetermined, percent: countObj.scoreUndetermined / this.data.totalContacts, description: this.contactBreakdownDescriptions.scoreUndetermined });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score0, value: countObj.score0, percent: countObj.score0 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score0 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score1, value: countObj.score1, percent: countObj.score1 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score1 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score2, value: countObj.score2, percent: countObj.score2 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score2 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score3, value: countObj.score3, percent: countObj.score3 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score3 });
        this.contactBreakdown.push({ label: this.contactBreakdownLabels.score4, value: countObj.score4, percent: countObj.score4 / this.data.totalContacts, description: this.contactBreakdownDescriptions.score4 });
      }

      this.unresponsiveChart = {
        name: 'Unresponsive',
        value: this.data.unresponsiveContacts,
        total: this.data.totalContactsAnalyzed
      };

      this.wastefulChart = {
        name: 'Wasteful',
        value: this.data.wastefulSends,
        total: this.data.totalSends
      };
    });

    this.darkPoolService.getDemoData('summary-bouncebacks').then(bouncebacks => {
      this.bouncebacks = bouncebacks;
      this.bouncebackChart = {
        name: 'Bouncebacks',
        value: this.bouncebacks.totalBounces,
        total: this.data.totalSends
      };

      this.misclassifiedChart = {
        name: 'Misclassified',
        value: this.bouncebacks.hardBouncebacksValid,
        total: this.bouncebacks.allBouncebacksValid
      };
    });

    this.darkPoolService.getDemoData('average-sends').then(avgSends => {
      this.averageSends = avgSends;
    });
  } //end: loadDemoData()
}

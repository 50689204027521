import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'filter-selector-modal',
  template: require('./filter-selector.html')
})
export class FilterSelectorComponent {
  eloquaCampaigns: any[] = [];
  treatments: any[] = [];
  report: string;
  maxTreatments: number;
  currentFilter: {};

  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;

    this.loading = true;
    this.title = '';
    this.searchText = '';
    this.alerts = [];

    this.filterLocal = {
      options: [
        {
          display: 'Eloqua Campaign',
          value: 'eloquaCampaigns'
        }
      ],
      selected: {}
    };
    this.selectedTreatmentCount = 0;
  }

  ngOnInit() {
    // Set title
    switch (this.report) {
    case 'globalFunnel':
      this.title = 'Funnel Data';
      break;
    case 'frequencyIntelligence':
      this.title = 'Frequency Intelligence Data';
      break;
    case 'globalWho':
      this.title = 'Who Responded Data';
      break;
    case 'globalSto':
      this.title = 'Send Time Data';
      break;
    }

    // Add emails to dropdown
    if(this.treatments && this.treatments.length > 0) {
      this.filterLocal.options.push({
        display: 'Email',
        value: 'treatments'
      });
    }

    // Set current selection
    if(this.currentFilter.filterBy == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        campaign.selected = false;

        if((this.report == 'globalWho' || !campaign.transientIds) && this.currentFilter.ids.indexOf(campaign.campaign_id) > -1) {
          campaign.selected = true;
        }
        else if(this.report !== 'globalWho' && campaign.transientIds) {
          campaign.transientIds.forEach(transientId => {
            if(this.currentFilter.ids.indexOf(transientId) > -1) {
              campaign.selected = true;
            }
          });
        }
      });

      // Sort with selected first
      this.eloquaCampaigns.sort((a, b) => {
        if(a.selected === b.selected) return 0;
        if(a.selected) return -1;
        else return 1;
      });
    }
    else if(this.currentFilter.filterBy == 'treatments') {
      this.treatments.forEach(treatment => {
        treatment.selected = false;
        if(this.currentFilter.ids.indexOf(treatment.treatment_id) > -1) {
          treatment.selected = true;
          this.selectedTreatmentCount++;
        }
      });

      // Sort with selected first
      this.treatments.sort((a, b) => {
        if(a.selected === b.selected) return 0;
        if(a.selected) return -1;
        else return 1;
      });

      this.eloquaCampaigns.forEach(campaign => {
        campaign.selected = false;
      });
    }

    this.filteredTreatments = cloneDeep(this.treatments);
    this.filteredEloquaCampaigns = cloneDeep(this.eloquaCampaigns);

    if(this.currentFilter.filterBy == 'treatments') {
      this.filterLocal.selected = this.filterLocal.options[1];
    }
    else {
      this.filterLocal.selected = this.filterLocal.options[0];
    }

    this.loading = false;
  }

  toggleCheckbox(selected, type) {
    this.alerts = [];
    selected.selected = !selected.selected;

    // toggle selection in full list (not just filtered list)
    if(this.filterLocal.selected.value == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        if(campaign.campaign_id == selected.campaign_id) {
          campaign.selected = selected.selected;
        }
      });
    }
    else {
      this.treatments.forEach(treatment => {
        if(treatment.treatment_id == selected.treatment_id) {
          treatment.selected = selected.selected;
        }
      });
    }

    // For who report, only allow maxTreatments to be selected at a time
    if(type == 'email' && this.report == 'globalWho') {
      if(this.selectedTreatmentCount >= this.maxTreatments && selected.selected) {
        selected.selected = false;

        this.alerts.push({ type: 'warning', msg: `There are already ${this.maxTreatments} emails selected. Uncheck a different email to select this one.` });
      }
      else if(selected.selected) {
        this.selectedTreatmentCount++;
      }
      else {
        this.selectedTreatmentCount--;
      }
    }
  }

  searchFilter() {
    var searchString = this.searchText.toLowerCase();

    if(searchString && this.filterLocal.selected.value == 'eloquaCampaigns') {
      function searchFilter(campaign) {
        return campaign.name && campaign.name.toLowerCase().indexOf(searchString) > -1 || campaign.campaign_id && campaign.campaign_id.toString().indexOf(searchString) > -1;
      }

      this.filteredEloquaCampaigns = this.eloquaCampaigns.filter(searchFilter);
    }
    else if(searchString) {
      function searchFilter(treatment) {
        return (
          treatment.name && treatment.name.toLowerCase().indexOf(searchString) > -1
          || treatment.subject && treatment.subject.toLowerCase().indexOf(searchString) > -1
          || treatment.treatment_id && treatment.treatment_id.toString().indexOf(searchString) > -1
        );
      }
      this.filteredTreatments = this.treatments.filter(searchFilter);
    }
  }

  changeFilterBy(selected) {
    this.filterLocal.selected = selected;
    this.searchText = '';
  }

  ok() {
    this.okClicked = true;
    var response = {
      ids: [],
      filterBy: this.filterLocal.selected.value,
      numberSelected: 0
    };

    if(this.filterLocal.selected.value == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        if(campaign.selected) {
          response.numberSelected++;
          if(this.report == 'globalWho' || !campaign.transientIds) {
            response.ids.push(campaign.campaign_id);
          }
          else {
            campaign.transientIds.forEach(transientId => {
              response.ids.push(transientId);
            });

            response.ids.push(campaign.campaign_id);
          }
        }
      });
    }
    else {
      this.treatments.forEach(treatment => {
        if(treatment.selected) {
          response.numberSelected++;
          response.ids.push(treatment.treatment_id);
        }
      });
    }

    this.response = response;
    this.bsModalRef.hide();
  }

  cancel() {
    this.okClicked = false;
    this.bsModalRef.hide();
  }
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { PersonaService } from '../persona.service';
import { AuthService } from '../../../../components/auth/auth.service';
import { OrgService } from '../../../../components/auth/org.service';
import { error } from '@angular/compiler/src/util';

@Component({
    selector: 'persona-voice',
    template: require('./persona-voice.html')
})
export class PersonaVoiceComponent {
    @Input() persona;
    @Input() orgId;

    static parameters = [HttpClient, PersonaService, AuthService, OrgService];
    constructor(http: HttpClient, personaService: PersonaService, authService: AuthService, orgService: OrgService) {
        this.http = http;
        this.personaService = personaService;
        this.authService = authService;
        this.orgService = orgService;

        this.generatorBaseUrl = '/api/generator';
    }

    ngOnInit() {
      console.log("persona", this.persona);

      this.personaVoice = {
        personaId: this.persona.personaId,
        personaName: this.persona.name,
        personaAttributes: '',
        generatedPersonaProfile: '',
        generatedVoice: '',
        show: false,
        collapsed: false,
        profileCollapsed: true
      };

      this.loadingPersonaVoice = false;
      this.authService.getCurrentOrg().then(org => {
        if(org) {
          this.organization = org;
          console.log( "Organization: ", this.organization );
          this.initPersonaVoice();
        }
      });

      this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
        this.organization = org;
        this.initPersonaVoice();
      });
    }

    ngOnChanges(changes: SimpleChanges) {
      console.log("Persona Voice Component - ngOnChanges: ", changes);
      for(const propName in changes) {
        if(propName === 'persona') {
          if(typeof this.organization !== 'undefined') {
            this.initPersonaVoice();
          }
        }
      }
    }

    personaVoiceChanged() {
      this.alerts = []
    }

    initPersonaVoice() {
      console.log("init new persona voice");
      this.personaVoice = {
        personaId: this.persona.personaId,
        personaName: this.persona.name,
        personaAttributes: '',
        generatedPersonaProfile: '',
        generatedVoice: '',
        show: false,
        collapsed: false,
        profileCollapsed: true
      };

      // load persona voice if it exists
      this.http.get(`/api/persona-voices/${this.personaVoice.personaId}`)
        .toPromise()
        .then(personaVoice => {
          console.log("Persona voice found: ", personaVoice);
          this.personaVoice = personaVoice;
          this.personaVoice.show = true;
          this.personaVoice.collapsed = false;
          this.personaVoice.profileCollapsed = true;
        }, () => {
          console.log("Persona voice not found for this persona",);
        });
    }

    allowEditing() {
      return this.authService.hasRole('user');
    }

    buildTopEmailDetailsDescription(topEmailDetails) {
      var topEmailDetailsDescription = '';
      topEmailDetails.forEach((email, i) => {
        topEmailDetailsDescription += `Email ${i + 1}:\n`;
        topEmailDetailsDescription += `Subject: ${email.subject}\n`;
        if(email.strippedHtmlText) {
          topEmailDetailsDescription += `Body: ${email.strippedHtmlText}\n\n`;
        } else {
          topEmailDetailsDescription += `Body: ${email.strippedPlainText}\n\n`;
        }
        
      });
      console.log("Top email details description: ", topEmailDetailsDescription);
      return topEmailDetailsDescription;
    }

    getPersonaVoice(topEmailDetails, personaProfile, savePersonaVoice) {
      var personaVoiceParams = {
        topEmailDetails: this.buildTopEmailDetailsDescription(topEmailDetails),
        personaProfile
      }

      // TODO: move this to generator service?
      this.http
      .post(`${this.generatorBaseUrl}/create-persona-voice`, personaVoiceParams)
      .toPromise()
      .then(
        response => {
          console.log("Persona voice:", response);
          var voiceResponse = response[0].message.content.match(/<voice>(.*?)<\/voice>/s)[1];
          this.personaVoice.generatedVoice = `<strong>Persona Voice & Tone Guidelines for ${this.persona.name}</strong><br>`;
          this.personaVoice.generatedVoice += voiceResponse.replace(/(?:\r\n|\r|\n)/g, '<br>'); //convert newlines (\n) to <br>
          this.personaVoice.show = true;
          this.loadingPersonaVoice = false;
          if(savePersonaVoice) {
            this.savePersonaVoice();
          }
        },
        error => {
          //this.loggerService.logMessage('Error generating persona voice.', 'error', error);
          console.log("Error generating persona voice: ", error);
          this.loadingPersonaVoice = false;
          this.alerts.push({ type: 'warning', msg: 'Error generating persona voice.' });
        }
      );
    }

    generatePersonaVoice(savePersonaVoice) {
      console.log("Generate persona voice");
      this.alerts = [];
      this.loadingPersonaVoice = true;

      // First, get top email details
      this.personaService.getTopEmailDetailsForPersona(this.persona.personaId, this.orgId)
          .then((topEmailDetails) => {
              console.log("Top email details: ", topEmailDetails);
              if(this.personaVoice.personaAttributes) {
                var personaDescription = this.personaVoice.personaAttributes;
                this.http
                .post(`${this.generatorBaseUrl}/create-persona-profile`, {personaDescription})
                .toPromise()
                .then(
                  response => {
                    console.log("Persona profile:", response)
                    var profileResponse = response[0].message.content.match(/<profile>(.*?)<\/profile>/s)[1];
                    this.personaVoice.generatedPersonaProfile = profileResponse.replace(/(?:\r\n|\r|\n)/g, '<br>'); //convert newlines (\n) to <br>;
                    this.getPersonaVoice(topEmailDetails, this.personaVoice.generatedPersonaProfile, savePersonaVoice);
                  },
                  error => {
                    //this.loggerService.logMessage('Error generating persona voice.', 'error', error);
                    console.log("Error generating persona voice: ", error);
                    this.loadingPersonaVoice = false;
                    this.alerts.push({ type: 'warning', msg: 'Error generating persona voice.' });
                  }
                );
              } else {
                this.getPersonaVoice(topEmailDetails, '', savePersonaVoice);
              }
          })
          .catch((error) => {
              console.error("Error retrieving top email details: ", error);
              this.loadingPersonaVoice = false;
              this.alerts.push({ type: 'warning', msg: 'Error generating persona voice.' });
          });
    }

    updateOrgPersonaVoices(isRemove) {
      this.alerts = [];
      this.savingPersonaVoice = true;
      this.orgService
        .update(this.organization)
        .toPromise()
        .then(
          () => {
            console.log("Organization successfully updated.");
            if(!isRemove) {
              this.alerts.push({ type: 'success', msg: 'Persona Voice successfully saved.' });
            }
            this.authService.currentOrg = this.organization;
            setTimeout(() => {
              this.savingPersonaVoice = false;
            });
          },
          //Error Handling...
          error => {
            var errorMsg = 'Error saving persona voice.';
            if(isRemove) {
              errorMsg = 'Error removing persona voice.';
            }
            this.alerts.push({ type: 'warning', msg: errorMsg });
            console.log('Error saving persona voice', error);
          }
        );
    }

    savePersonaVoice() {
      var isRemove = false;
      // If we already have a persona voice for this persona, remove it first
      this.http.get(`/api/persona-voices/${this.personaVoice.personaId}`)
        .toPromise()
        .then(response => {
          console.log("Persona voice found: ", response);
          if(response) {
            this.http.put(`/api/persona-voices/${this.personaVoice.personaId}`, this.personaVoice)
              .toPromise()
              .then(response => {
                console.log("Persona voice saved: ", response);
              }, error => {
                console.error("Error saving persona voice: ", error);
              });
          }
          else {
            this.http.post(`/api/persona-voices/${this.personaVoice.personaId}`, this.personaVoice)
              .toPromise()
              .then(response => {
                console.log("Persona voice saved: ", response);
              }, error => {
                console.error("Error saving persona voice: ", error);
              });
          }
        }, error => {
          this.http.post(`/api/persona-voices/${this.personaVoice.personaId}`, this.personaVoice)
            .toPromise()
            .then(response => {
              console.log("Persona voice saved: ", response);
            }, error => {
              console.error("Error saving persona voice: ", error);
            });
        });
      console.log("call persona voice endpoint");
      
    }

    removePersonaVoice() {
      this.alerts = [];
      var isRemove = true;
      if(confirm('Are you sure you want to remove this Persona Voice?')) {
        this.http.delete(`/api/persona-voices/${this.personaVoice.personaId}`)
        .toPromise()
        .then(response => {
          console.log("Persona voice removed: ", response);
          this.initPersonaVoice();
        }, error => {
          console.log("Error removing persona voice: ", error);
        });
      }
    }


    // FOR TESTING ONLY
    refreshAllPersonaVoices() {
      console.log('refresh all persona voices');
      var orgId = this.organization._id;
      this.http.post('/api/persona-voices/refresh/all-persona-voices', {orgId})
        .toPromise()
        .then(response => {
          console.log('Response from refresh all persona voices:', response);
        }, error => {
          console.error('Error refreshing all persona voices:', error);
        });
    }
}

// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';

@Injectable({
  providedIn: 'root'
})
export class DarkPoolService {
  baseUrl = '/api/darkpool';

  static parameters = [HttpClient, AuthService, ImpersonationService];
  constructor(http: HttpClient, authService: AuthService, impersonation: ImpersonationService) {
    this.http = http;
    this.authService = authService;
    this.impersonation = impersonation;
    this._sampleStats = {};
    this._tempSettings = null; //for temporary settings that only last a session.

    this.contactBreakdownLabels = {
      scoreUndetermined: 'Nurturing',
      score0: 'Keep sending', //green
      score1: 'Investigate', //yellow
      score2: 'Throttle and investigate', //orange
      score3: 'Stop sending and shift to a different channel', //red
      score4: 'Remove' //black
    };

    this.contactBreakdownDescriptions = {
      scoreUndetermined: 'Keep sending emails and nurturing these newer contacts. They are not included in your Dark Pool.',
      score0: 'Keep sending emails. Contacts are healthy and responding.',
      score1: 'Investigate. Contacts somewhat fatigued. Good chance they will re-engage.',
      score2: 'Send fewer emails. Contacts are fatigued. Slight chance they will re-engage.',
      score3: 'Stop sending emails. Try another channel besides email.',
      score4: 'Contacts are dark. Stop sending emails and remove them from the database.'
    };

    this.init();
  }

  //Save a few sample data stats, so we don't have to re-query them.
  get sampleStats() {
    return this._sampleStats;
  }

  set sampleStats(id) {
    this._sampleStats = id;
  }

  //Save changes to time-period
  get tempSettings() {
    return this._tempSettings;
  }

  set tempSettings(ls) {
    this._tempSettings = ls;
  }

  getContactBreakdownLabels() {
    return this.contactBreakdownLabels;
  }

  getContactBreakdownDescriptions() {
    return this.contactBreakdownDescriptions;
  }

  updateScores(contactIDs) {
    //Change numeric scores to labels.
    contactIDs.forEach(item => {
      switch (item.contact_score) {
      case -1:
      default:
        item.contact_score = this.contactBreakdownLabels.scoreUndetermined;
        break;
      case 0:
        item.contact_score = this.contactBreakdownLabels.score0;
        break;
      case 1:
        item.contact_score = this.contactBreakdownLabels.score1;
        break;
      case 2:
        item.contact_score = this.contactBreakdownLabels.score2;
        break;
      case 3:
        item.contact_score = this.contactBreakdownLabels.score3;
        break;
      case 4:
        item.contact_score = this.contactBreakdownLabels.score4;
        break;
      }
    });
  }

  encodeScore(label) {
    //Change label to numeric score.
    switch (label) {
    case this.contactBreakdownLabels.scoreUndetermined:
    default:
      return -1;
    case this.contactBreakdownLabels.score0:
      return 0;
    case this.contactBreakdownLabels.score1:
      return 1;
    case this.contactBreakdownLabels.score2:
      return 2;
    case this.contactBreakdownLabels.score3:
      return 3;
    case this.contactBreakdownLabels.score4:
      return 4;
    }
  }

  showDarkPool(currentOrg, isAdmin, isImpersonating) {
    var show = false;
    if(typeof currentOrg !== 'undefined'
       && currentOrg.active
      && (currentOrg.darkPoolSetting === 'freemium' || currentOrg.darkPoolSetting === 'premium' || currentOrg.darkPoolSetting === 'admin' && (isAdmin || isImpersonating))) {
      show = true;
    }
    return show;
  }

  getMotivaTagInfo(motivaTag) {
    var motivaTagInfo = this.motivaTagDefs[motivaTag];
    if(!motivaTagInfo) {
      motivaTagInfo = {
        class: 'unknown',
        description: 'Motiva Tag not found.',
        recommendation: ''
      };
    }
    return motivaTagInfo;
  }

  getMotivaTagClass(motivaTag) {
    var motivaTagInfo = this.motivaTagDefs[motivaTag];
    if(!motivaTagInfo) {
      motivaTagInfo = {
        class: 'unknown',
        description: 'Motiva Tag not found.',
        recommendation: ''
      };
    }
    return motivaTagInfo.class;
  }

  /**
   * Get the latest Dark Pool settings from the currentOrg.
   *
   * @param {String} currentOrg
   * @return {Object}
   *
   */
  getLatestDarkPoolSettings(currentOrg) {
    if(!currentOrg.darkPoolPeriods || currentOrg.darkPoolPeriods.length === 0) {
      return {};
    }
    else if(currentOrg.darkPoolPeriods.length === 1) {
      return currentOrg.darkPoolPeriods[0];
    }
    else {
      //TODO: find the most recent Dark Pool period and return.
      return currentOrg.darkPoolPeriods[0];
    }
  }

  /**
   * Domains that should be ignored in all dark pool queries.
   *
   */
  getDomainsToIgnore(currentOrg) {
    if(currentOrg) {
      if(currentOrg.darkPoolDomains && typeof currentOrg.darkPoolDomains.domainsToIgnore !== 'undefined') {
        return currentOrg.darkPoolDomains.domainsToIgnore;
      }
    }
    return [];
  }

  setDomainsToIgnore(currentOrg, domainsToIgnore) {
    if(currentOrg) {
      if(typeof currentOrg.darkPoolDomains === 'undefined' || !currentOrg.darkPoolDomains) {
        currentOrg.darkPoolDomains = {};
      }
      if(typeof currentOrg.darkPoolDomains.domainsToIgnore === 'undefined') {
        currentOrg.darkPoolDomains.domainsToIgnore = [];
      }
      currentOrg.darkPoolDomains.domainsToIgnore = domainsToIgnore;
      this.authService.updateCurrentOrg(currentOrg);
    }
  }

  /**
   * Forms that should be ignored in all dark pool queries.
   *
   */
  getFormsToIgnore(currentOrg) {
    if(currentOrg) {
      if(currentOrg.darkPoolForms && typeof currentOrg.darkPoolForms.formsToIgnore !== 'undefined') {
        return currentOrg.darkPoolForms.formsToIgnore;
      }
    }
    return [];
  }

  setFormsToIgnore(currentOrg, formsToIgnore) {
    if(currentOrg) {
      if(typeof currentOrg.darkPoolForms === 'undefined' || !currentOrg.darkPoolForms) {
        currentOrg.darkPoolForms = {};
      }
      if(typeof currentOrg.darkPoolForms.formsToIgnore === 'undefined') {
        currentOrg.darkPoolForms.formsToIgnore = [];
      }
      currentOrg.darkPoolForms.formsToIgnore = formsToIgnore;
      this.authService.updateCurrentOrg(currentOrg);
    }
  }

  /**
   * Eloqua Segments that should be ignored in all dark pool queries.
   *
   */
  getSegmentsToIgnore() {
    var segmentsToIgnore = JSON.parse(localStorage.getItem('dpSegmentsToIgnore'));
    return segmentsToIgnore ? segmentsToIgnore : [];
  }

  setSegmentsToIgnore(segmentsToIgnore) {
    localStorage.setItem('dpSegmentsToIgnore', JSON.stringify(segmentsToIgnore));
  }


  /**
   * Get Dark Pool summary data for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Object}
   *
   */
  getSummaryData(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/summary`, { params }).toPromise();
  }

  /**
   * Get summary of bounceback DP data for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Object}
   *
   */
  getBouncebackSummary(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/summaryBouncebacks`, { params }).toPromise();
  }

  /**
   * Check why a Contact has a paricular score.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} eloquaContactId - Contact ID to check.
   * @return {Object}
   *
   */
  checkContact(periodIndex, eloquaContactId) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('eloquaContactId', eloquaContactId);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/checkContact`, { params }).toPromise();
  }

  /**
   * Get domain breakdown for Unresponsive contacts for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} filterActive - all, only, or exclude
   * @return {Array}
   *
   */
  getBreakdownByDomain(periodIndex, filterActive) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('filterActive', filterActive);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/unresponsivebydomain`, { params }).toPromise();
  }

  /**
   * Get bounce data by domain for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} motivaTag - Optional motiva tag to get data for.
   * @param {String} limit - Optional, number of rows to return for showing sample data.
   * @return {Array}
   *
   */
  getBouncesByDomain(periodIndex, motivaTag, limit) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    if(motivaTag) params = params.append('motivaTag', motivaTag);
    if(limit) params = params.append('limit', limit);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/bouncesbydomain`, { params }).toPromise();
  }

  /**
   * Get bounceback / Motiva Tag breakdown for a domain and the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - Optional, only get breakdown for this domain.
   * @param {String} limit - Optional, number of rows to return for showing sample data.
   * @return {Array}
   *
   */
  getBreakdownByMotivaTag(periodIndex, domain, limit) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    if(domain) params = params.append('domain', domain);
    if(limit) params = params.append('limit', limit);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/bouncebackbreakdown`, { params }).toPromise();
  }

  /**
   * Get bounceback counts by day for an org.
   *
   * @param {String} orgId - The organization we are analyzing
   * @return {Array}
   *
   */
  getBouncebackCountsByDay(orgId) {
    var params = new HttpParams();
    params = params.append('orgId', orgId);
    return this.http.get(`${this.baseUrl}/bouncebackcountsbyday`, { params }).toPromise();
  }

  /**
   * Get most recent bounceback loaded for an org.
   *
   * @param {String} orgId - The organization we are analyzing
   * @return {Array}
   *
   */
  getLatestBouncebacksDay(orgId) {
    var params = new HttpParams();
    params = params.append('orgId', orgId);
    return this.http.get(`${this.baseUrl}/bouncebacklatest`, { params }).toPromise();
  }

  /**
   * Get campaign performance for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} filterActive - all, only, or exclude
   * @return {Object}
   *
   */
  getCampaignPerformance(periodIndex, filterActive) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('filterActive', filterActive);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/campaignperformance`, { params }).toPromise();
  }

  /**
   * Get performance for a specific Eloqua campaign.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} eloquaCampaignId
   * @return {Object}
   *
   */
  getPerformanceByCampaign(periodIndex, eloquaCampaignId) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('eloquaCampaignId', eloquaCampaignId);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/performancebycampaign`, { params }).toPromise();
  }

  /**
   * Get list of performance Eloqua campaigns.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Object}
   *
   */
  getPerformanceCampaigns(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/performancecampaigns`, { params }).toPromise();
  }

  /**
   * Get domains with possible scanners for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  getScanners(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/scanners`, { params }).toPromise();
  }

  /**
   * Get bots for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  getBots(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/bots`, { params }).toPromise();
  }

  /**
   * Get bot details for a single contact id.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} contactId - The Contact ID.
   * @return {Array}
   *
   */
  getBotDetails(periodIndex, contactId) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('contactId', contactId);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/botdetails`, { params }).toPromise();
  }

  /**
   * Get threshold possbilities for the passed in org.
   *
   * @param {String} orgId - The organization we are analyzing
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  getThresholds(orgId, periodIndex, organization) {
    var params = new HttpParams();
    params = params.append('orgId', orgId);
    params = params.append('periodIndex', periodIndex);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //Get domainsToIgnore from the org that was passed in (used by admins).
    var domainsToIgnore = this.getDomainsToIgnore(organization);
    params = params.append('domainsToIgnore', domainsToIgnore);

    return this.http.get(`${this.baseUrl}/thresholds`, { params }).toPromise();
  }

  /**
   * Check threshold possbilities for the passed in org and dates.
   *
   * @param {String} orgId - The organization we are analyzing
   * @param {Date} startDate - The time period startDate to check
   * @param {Date} endDate - The time period endDate to check
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  checkThresholds(orgId, startDate, endDate, periodIndex) {
    var params = new HttpParams();
    params = params.append('orgId', orgId);
    params = params.append('periodIndex', periodIndex);
    params = params.append('startDate', startDate.toISOString());
    params = params.append('endDate', endDate.toISOString());

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    return this.http.get(`${this.baseUrl}/thresholds`, { params }).toPromise();
  }

  /**
   * Get average sends per week.
   *
   * @param {String} orgId - The organization we are analyzing
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  getAverageSends(orgId, periodIndex) {
    var params = new HttpParams();
    params = params.append('orgId', orgId);
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    return this.http.get(`${this.baseUrl}/averagesends`, { params }).toPromise();
  }

  /**
   * Get the domains from the Average Sends histogram.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  exportAverageSendsDomains(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    return this.http.get(`${this.baseUrl}/exportaveragesendsdomains`, { params }).toPromise();
  }

  /**
   * Get Dark Pool domains that have typos -- typically Discard Queue bouncebacks.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Object}
   *
   */
  getDomainsWithTypos(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/domainsWithTypos`, { params }).toPromise();
  }

  /**
   * Get a list of contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - the domain of the contacts       -- can only use one of the domain params.
   * @param {String} domains - list of domains of the contacts -- can only use one of the domain params.
   * @return {Array}
   *
   */
  exportDomainsWithTypos(periodIndex, domain, domains) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    if(domain) {
      params = params.append('domain', domain);
    }

    if(domains) {
      params = params.append('domains', domains);
    }

    return this.http.get(`${this.baseUrl}/exportDomainsWithTypos`, { params }).toPromise();
  }

  /**
   * Get a list of all contact id's
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  exportAllContactIDs(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/exportall`, { params }).toPromise();
  }

  /**
   * Get a list of all contact id's by Score
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {Integer} score - the score for the contacts we want to export.
   * @return {Array}
   *
   */
  exportContactIDsByScore(periodIndex, score) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('score', score);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/exportbyscore`, { params }).toPromise();
  }

  /**
   * Get a list of Unresponsive contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - the domain of the contacts
   * @param {String} filterActive - all, only, or exclude
   * @return {Array}
   *
   */
  exportUnresponsiveContactIDs(periodIndex, domain, filterActive) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('domain', domain);
    params = params.append('filterActive', filterActive);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/exportunresponsive`, { params }).toPromise();
  }

  /**
   * Get a list of all Unresponsive contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} filterActive - all, only, or exclude
   * @return {Array}
   *
   */
  exportAllUnresponsiveContactIDs(periodIndex, filterActive) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('filterActive', filterActive);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    return this.http.get(`${this.baseUrl}/exportunresponsive`, { params }).toPromise();
  }

  /**
   * Get a list of Unresponsive contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domains - array of domains for the contacts
   * @param {String} filterActive - all, only, or exclude
   * @return {Array}
   *
   */
  exportDomainsUnresponsiveContactIDs(periodIndex, domains, filterActive) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('domains', domains);
    params = params.append('filterActive', filterActive);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
      params = params.append('threshold', this._tempSettings.threshold);
    }

    return this.http.get(`${this.baseUrl}/exportunresponsivedomains`, { params }).toPromise();
  }

  /**
   * Get a list of Bounced contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} motivaTag - the motiva tag (bounceback type)
   * @param {String} domain - the domain of the contacts
   * @return {Array}
   *
   */
  exportBouncebackContactIDs(periodIndex, motivaTag, domain) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('motivaTag', motivaTag);
    if(domain) params = params.append('domain', domain);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportbouncebackcontacts`, { params }).toPromise();
  }

  /**
   * Get a all Bounced contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - the domain of the contacts
   * @return {Array}
   *
   */
  exportAllBouncebackContactIDs(periodIndex, domain) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    if(domain) params = params.append('domain', domain);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportbouncebackcontacts`, { params }).toPromise();
  }

  /**
   * Get a list of Bounced contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} motivaTags - array of motiva tags (bounceback type)
   * @param {String} domain - the domain of the contacts
   * @return {Array}
   *
   */
  exportTagsBouncebackContactIDs(periodIndex, motivaTags, domain) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('motivaTags', motivaTags);
    if(domain) params = params.append('domain', domain);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportbouncebackcontactsfortags`, { params }).toPromise();
  }

  /**
   * Get the Bounceback report details for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - the domain of the contacts
   * @param {String} motivaTag - the motiva tag (bounceback type)
   * @return {Array}
   *
   */
  exportBouncebackReport(periodIndex, domain, motivaTag) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('domain', domain);
    params = params.append('motivaTag', motivaTag);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportbouncebackreport`, { params }).toPromise();
  }

  /**
   * Get a list of Scanner contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domain - the domain of the contacts
   * @return {Array}
   *
   */
  exportScannerContactIDs(periodIndex, domain) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('domain', domain);

    //Get domainsToIgnore and formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportscanners`, { params }).toPromise();
  }

  /**
   * Get a list of ALL Scanner contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Array}
   *
   */
  exportAllScannerContactIDs(periodIndex) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);

    //Get domainsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var domainsToIgnore = this.getDomainsToIgnore(currentOrg);
    params = params.append('domainsToIgnore', domainsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportscanners`, { params }).toPromise();
  }

  /**
   * Get a list of Scanner contact id's for the selected params.
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @param {String} domains - the list of domains of the contacts
   * @return {Array}
   *
   */
  exportDomainsScannerContactIDs(periodIndex, domains) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('domains', domains);

    //Get formsToIgnore from the org.
    var currentOrg = this.authService.getCurrentOrgSync();
    var formsToIgnore = this.getFormsToIgnore(currentOrg);
    params = params.append('formsToIgnore', formsToIgnore);

    //If the user changed the tempSettings, send it as params.
    if(this._tempSettings) {
      params = params.append('startDate', this._tempSettings.startDate.toISOString());
      params = params.append('endDate', this._tempSettings.endDate.toISOString());
    }

    return this.http.get(`${this.baseUrl}/exportscannersdomains`, { params }).toPromise();
  }

  /**
   * Get Dark Pool summary data for the current user's org.
   *
   * @param {String} periodIndex - Array index into organization.darkPoolPeriods to determing which period to use.
   * @return {Object}
   *
   */
  updateDarkPoolSettings(periodIndex, orgId) {
    var params = new HttpParams();
    params = params.append('periodIndex', periodIndex);
    params = params.append('orgId', orgId);
    return this.http.get(`${this.baseUrl}/updateDarkPoolSettings`, { params }).toPromise();
  }

  //****************************************************************************
  //TODO: Get this from a service.
  //****************************************************************************
  init() {
    this.motivaTagDefs = {
      Blacklisted: {
        class: 'hard',
        description: 'The recipient\'s mail server is not accepting email from the sender\'s address.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'Temporary Failure': {
        class: 'soft',
        description: 'Either the target server is down and not accepting connections, or that the server is temporarily refusing to accept the mail, but might accept it later. This might also be caused by a full mailbox.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'SPF Policy Violation': {
        class: 'analyze',
        description: 'Either the target server is down and not accepting connections, or the server is temporarily refusing to accept the mail, but might accept it later. This might also be caused by a full mailbox.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Generic Access Denied': {
        class: 'hard',
        description: 'The address is not recognized by the recipient system, such as email arriving at an incorrect server due to a DNS issue, or the recipient address isn\'t set up to receive email.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'No Recipient': {
        class: 'hard',
        description: 'The email address the sender sent to does not exist or has been disabled.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'Blocked By Recipient': {
        class: 'hard',
        description: 'The individual recipient has blocked the sender\'s address.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'Message Expired': {
        class: 'soft',
        description: 'The sending server was not able to deliver the message to the destination point and the email message in the queue expired.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Not Authenticated': {
        class: 'analyze',
        description: 'This usually means that you’re trying to send from an email address that is not allowed on the mail server you’re trying to use, as in, you’re entering the ‘from’ address examplename@gmail.com but using your Internet Service Provider’s mail server instead of Google’s.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Mail Loop': {
        class: 'analyze',
        description: 'An email loop is an infinite loop phenomenon, resulting from mail servers, scripts, or email clients that generate automatic replies or responses. If one such automatic response triggers another automatic response on the other side, an email loop is created.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Mailbox Full': {
        class: 'analyze',
        description: 'If your contact has so many emails in their inbox that they can’t receive any more, your emails will bounce back until there’s space for them.',
        recommendation: 'If you\'ve been seeing this problem for more than month, remove the problematic contacts from your database.'
      },
      'Routing Loop': {
        class: 'soft',
        description: 'The most common cause of this error is when an email address with an active autoresponder sends an email to another address that also has an autoresponder. This could result in creating a loop if there is not a way to break it.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Generic Not Authorized': {
        class: 'analyze',
        description: 'Generic message, could be linked to SPF policies.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      Spam: {
        class: 'hard',
        description: 'The email message contains a link, attachment, or pattern caught by the recipient\'s email server\'s filters as spam.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'No Tag': {
        class: 'analyze',
        description: 'Motiva was not able to infer a tag for the bounce, this can happen because there was no bounce message, or the system simply hasn\'t reached that level of coverage.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Relay Access Denied': {
        class: 'analyze',
        description: 'This usually means that you’re trying to send from an email address that is not allowed on the mail server you’re trying to use, as in, you’re entering the ‘from’ address examplename@gmail.com but using your Internet Service Provider’s mail server instead of Google’s.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Not Whitelisted': {
        class: 'soft',
        description: 'Third-party blacklists can cause other domains to reject your organization\'s messages. The recipient\'s settings could be configured to reject messages that aren’t sent from specific IP addresses or servers, preventing a message from going through.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Connection Issue': {
        class: 'soft',
        description: 'The recipient\'s mail server refused to establish a connection, or has trouble doing so. This can be either a temporary failure or could potentially be caused by blacklisting.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Message Delayed': {
        class: 'soft',
        description: 'Could be caused by multiple issues, but the email was not sent in time. It is unclear whether the email actually gets delivered with a delay, or doesn\'t get the email at all.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      Timeout: {
        class: 'soft',
        description: 'This could happen for several reasons, but the message was not sent on time due to some type of bottleneck, and the recipient does not get the email.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Message Oversize': {
        class: 'soft',
        description: 'The message that you are trying to send is above the size limit allowed by the recipient\'s mail server. This can be caused by large attachments in the sent email.',
        recommendation: 'Check the emails(s) being sent and try to send to these problematic contacts again with a smaller sized email.'
      },
      'Out Of Memory': {
        class: 'soft',
        description: 'The recipient\'s mail server has run  out of memory to deliver the email.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Administrative Prohibition': {
        class: 'analyze',
        description: 'This error message will mean any of the following: The domain used by your SMTP server is on Real-time blacklists,  The recipient uses a domain that is on Real-time blacklists, The SMTP server has a dynamic IP address.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Generic Permanent Failure': {
        class: 'hard',
        description: 'Very generic error that can be caused by a myriad of things.',
        recommendation: 'Export the misclassified contact id\'s and remove those contacts from your database.'
      },
      'Unrecognized Command': {
        class: 'analyze',
        description: 'There might be some conflicts due to significant differences in SMTP protocol, in case devices are out of date.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Transport Rules Fail': {
        class: 'analyze',
        description: 'A certain transport rule is failing, try disabling them one by one to find out which one was failing.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'No External Forwarding': {
        class: 'analyze',
        description: 'An email was forwarded from a domain that does not have permission to do so to other external domains.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'System Config Error': {
        class: 'analyze',
        description: 'A configuration in the recipient\'s domain is erroneous.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Generic Unable To Deliver': {
        class: 'soft',
        description: 'Email cannot be delivered, could be caused by multiple reasons.',
        recommendation: 'Wait and try to send to these problematic contacts again later.'
      },
      'Other Regions Not Allowed': {
        class: 'analyze',
        description: 'Specific error to Office365, The attempted mail delivery to Office365 used an MX record or smart host that contained an incorrect destination value for Office 365.',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      },
      'Discard Queue': {
        class: 'analyze',
        description: 'TBD',
        recommendation: 'Research the problem and try to send to these problematic contacts again after a resolution is found.'
      }
    };
  } //end: init()

  //Demo data
  getDemoData(type) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/global/dark-pool-${type}.json`)
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }
}

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DarkPoolService } from '../dark-pool.service';

import moment from 'moment-timezone';

@Component({
  selector: 'dark-pool-check-contact',
  template: require('./dark-pool-check-contact.html')
})
export class DarkPoolCheckContactComponent {
  static parameters = [BsModalRef, DarkPoolService];
  constructor(bsModalRef: BsModalRef, darkPoolService: DarkPoolService) {
    this.bsModalRef = bsModalRef;
    this.darkPoolService = darkPoolService;
    this.eloquaContactId = '';
    this.analyzedContactId = '';
    this.results = null;
    this.loading = false;
    this.contactBreakdownLabels = this.darkPoolService.getContactBreakdownLabels();
  }

  checkContact() {
    this.loading = true;
    this.results = null;
    this.analyzedContactId = this.eloquaContactId;

    //TODO: Where to get the periodIndex?
    this.darkPoolService.checkContact(0, this.eloquaContactId).then(data => {
      this.loading = false;
      this.results = data;
      //console.log('----> results:', this.results); //debug
    });
  }

  done() {
    this.bsModalRef.hide();
  }
}
